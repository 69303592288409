<template>
  <v-row class="justify-center">
    <v-col
      cols="12"
      sm="12"
      md="10"
      lg="8"
      xl="6"
    >
      <v-card
        outlined
        class="rounded-lg"
      >
        <v-card-text>
          <v-row
            v-for="(files, groupName) in attachments"
            :key="groupName"
            no-gutters
            class="mb-10"
          >
            <v-col cols="12">
              <div class="text-h6 pb-3">
                {{ groupName.split('-')[1] }}
              </div>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col
                  v-for="(attachment, index) in files"
                  :key="index"
                  cols="6"
                  sm="3"
                  md="3"
                  lg="2"
                  style="height: 160px"
                >
                  <div
                    class="attachment-placeholder"
                    style="height: 120px"
                  >
                    <a
                      v-if="$_is_image(attachment.attachment)"
                      class="attachment-container d-block rounded-lg"
                      :href="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                      target="_blank"
                    >
                      <img
                        :src="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                        alt="img"
                      >
                    </a>

                    <a
                      v-else
                      :href="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                      target="_blank"
                      class="attachment-container d-flex grey rounded-lg justify-center"
                      :class="$vuetify.theme.dark ? 'darken-3': 'lighten-4'"
                    >
                      <v-icon
                        v-if="$_is_pdf(attachment.attachment)"
                        style="font-size: 72px"
                      >
                        far fa-file-pdf
                      </v-icon>

                      <v-icon
                        v-if="$_is_doc(attachment.attachment)"
                        style="font-size: 72px"
                      >
                        far fa-file-word
                      </v-icon>

                      <v-icon
                        v-if="$_is_excel(attachment.attachment)"
                        style="font-size: 72px"
                      >
                        far fa-file-excel
                      </v-icon>
                    </a>

                    <div class="ellipses-text text-center pt-2">
                      <div>{{ attachment.name }}</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      attachments: []
    }
  },

  computed: {
    ...mapGetters(['authShareholder'])
  },

  mounted() {
    this.getAttachments()
  },

  methods: {
    getAttachments() {
      axios.post('attachment/client/company-attachments/', {}).then(res => {
        this.attachments = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>